form {
  font-family: "Roboto Serif";
}


input,
select,
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 14px;
}

/* .ui.form label input:-webkit-autofill,
label input:-webkit-autofill,
.ui.form .field.field input:-webkit-autofill,
.ui.form label input:-webkit-autofill:hover,
.ui.form label input:-webkit-autofill:active,
.ui.form .field.field input:-webkit-autofill:active,
.ui.form label input[type="date"]:-webkit-autofill:active,
.ui.form label input:-webkit-autofill:focus,
.ui.form .field.field input:-webkit-autofill:focus,
.ui.form input:not([type]):focus,
.ui.form .field.field input:focus,
.ui.form label input[type="date"]:-webkit-autofill:focus,
.ui.form label textarea:-webkit-autofill,
label textarea:-webkit-autofill,
.ui.form label textarea:-webkit-autofill:hover,
.ui.form label textarea:-webkit-autofill:focus,
label textarea:focus,
label textarea:active,
.ui.form label select:-webkit-autofill,
.ui.form label select:-webkit-autofill:hover,
.ui.form label select:-webkit-autofill:focus,
label select:active,
label select:focus,
label select:visited {
  -webkit-text-fill-color: #ffd800;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset !important;
  box-shadow: 0 0 0px 1000px #000 inset !important;
  background-color:#000 !important;
  border-color: #000 !important;
} */

.ui.form label input,
.ui.form input,
.ui.form label select,
.ui.form label textarea,
.field select {
  background-color: rgb(1, 123, 166, .7) !important;
  color: #fff !important;
}

.ui.form label,
.ui.form::target-text  {
  display: block;
  margin-bottom: 13px;
  font-size: 14px;
  font-weight: 200;
  color:rgb(1, 123, 166)
}

button[type="submit"],
input[type="submit"],
.go-back-btn {
  background: #ffd800;
  color: #000;
  text-transform: uppercase;
  border: none;
  padding: 20px;
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 10px;
  width: 100%;
}

button[type="submit"]:hover,
input[type="submit"]:hover,
.go-back-btn:hover {
  background: #000;
  color: #ffd800;
}

button[type="submit"]:active,
input[type="button"]:active,
input[type="submit"]:active,
.go-back-btn:active {
  transition: 0.3s all;
  transform: translateY(3px);
  border: 1px solid transparent;
  opacity: 0.8;
}

input:disabled {
  opacity: 0.4;
}

input[type="button"]:hover {
  transition: 0.3s all;
}

button[type="submit"],
input[type="button"],
input[type="submit"],
.go-back-btn {
  -webkit-appearance: none;
  cursor: pointer;
}

button {
  display: block;
  appearance: none;
  border: 1px solid #333;
  margin-bottom: 20px;
  text-transform: uppercase;
  padding: 10px 20px;
  border-radius: 4px;
}

.request-form-container h3.ui.header,
.driver-application-container h3.ui.header {
  font-family: "Roboto Serif";
  color:rgb(1, 123, 166)
}

pre {
  color: white;
  white-space: pre;
}

.ui.form {
  max-width: 450px;
  margin: auto;
}

.error::before {
  display: inline;
  content: "⚠ ";
}

.error {
  color: red
}
