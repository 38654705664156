body {
  box-sizing: border-box;
  font-family: "Roboto Serif";
}
.home-container {
  padding-bottom: 100px;
}

/*Navigation*/
.ui.secondary.pointing.menu {
  border-bottom: none;
  padding-bottom: 6px;
  background-color: rgb(1, 123, 166);
  margin-bottom: 0;
}
.ui.secondary.pointing.menu .item {
  color: rgb(243, 232, 222);
}

.ui.secondary.pointing.menu .active.item,
.ui.secondary.pointing.menu a.item:hover {
  color: #ffd800;
}

.logo {
  width: 145px;
}

.home-page-logo {
  margin: auto;
  width: 465px;
  z-index: 5;
}

.message.hiring-banner {
margin-top: 0;
color: black !important;
box-shadow: #ffd800;
background-color: #ffd800 !important;
}

.hiring-banner .content .header  {
  color: black !important;
}

.home-forms-section {
  background-color: rgb(1, 123, 166) !important;
}

.grayscale-wrapper {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
.welcome-header {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 650px;
  width: auto;
  margin: 0 auto;
  text-align: center;
  position: relative; /*position overlay*/
  margin-bottom: 1.5rem;
}

.overlay-background {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #000;
  height: 100%;
  width: 100%;
  opacity: 0.68;
}
.welcome-background-images {
  height: 100%;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.centered-welcome {
  z-index: 1;
}

.hiring-header {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
}
.hiring-header .hiring-button {
  position: absolute;
  bottom: 15%;
}

h1.ui.header,
h2.ui.header,
.ui.cards > .card > .content > .header,
.ui.card > .content > .header,
.ui.menu {
  font-family: "Roboto Serif";
}

.centered-welcome h1.ui.header {
  margin-bottom: 0px;
  font-size: 3.65em;
  font-weight: 700;
  color: rgb(243, 232, 222);
}
.centered-welcome h2.ui.header {
  font-size: 1.7em;
  font-weight: normal;
  color: rgb(243, 232, 222);
}

.ui.vertical.segment.intro-section,
.ui.vertical.segment.business-info-section {
  padding: 8em 0em;
  border-bottom-style: none;
  border-top-style: none;
}
.intro-section-paragraph {
  font-size: 1.33em;
}

.ui.button {
  font-family: "Roboto Serif";
  font-weight: "normal";
  cursor: pointer;
}

.button.request-button {
  background-color: rgb(1, 123, 166);
  color: rgb(243, 232, 222);
}

.button.request-button:hover {
  background-color: #000;
  color: #ffd800;
}

.business-info {
  justify-content: center;
  padding-bottom: 50px;
  padding-top: 50px;
}
.covid-card-header {
  text-align: center;
}

.mask-wearer {
  width: 160px;
  margin: auto;
}
.info-header {
  text-align: center;
  margin-bottom: 50px;
}
.info-card-one,
.info-card-two,
.info-card-three {
  border: none;
  margin-bottom: 30px;
  height: 100%;
}

.request-form-segment {
  margin-top: 0;
  border-radius: 0;
  padding-bottom: 50px;
  margin-bottom: 0;
  padding-top: 50px;
}

.contact-form-container, .busSubmissionThankyou, .driver-application-container {
  max-width: 425px;
  margin: auto;
  margin-top: 30px;
  color: rgb(1, 123, 166);
}

.busSubmissionThankyou {
min-height: 350px;
padding-top: 50px;
}
.contact-form-header {
  color: rgb(1, 123, 166) !important;
}
.contact-form,
.home-request-bus {
  font-weight: 900;
}
.text-muted {
  color: rgb(243, 232, 222) !important;
}

.request-form-container h1.ui.header,
.request-form-container h2.ui.header,
.contact-form-header h1.ui.header {
  color: rgb(1, 123, 166);
}

.ui.inverted.segment.footer {
  margin: 5em 0em 0em;
  padding: 5em 0em;
  background-color: rgb(1, 123, 166);
}
.ui.section.divider.footer-divider {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.plusfaq {
  color:rgb(1, 123, 166)
}

.accordion .content.active p{
  padding-left: 47.44px;
}

.bus-calculator {
  width: 100%;
  margin: auto 0 0;
}

.finalStepSubmit {
  padding: 15px;
}

.terms-conditions {
  text-decoration: underline;
}

/*media queries*/

@media (max-width: 768px) {
  .form-steps {
    display: none !important;
  }

  .order-review-page p {
    font-size: .85rem;
  }

  .order-review-page i.huge.huge.huge.icons {
    font-size: 2.6em;
  }

}
